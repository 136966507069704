import router from '@/router'
import {
  getCurrentInstance,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { MARKUP_FILTER_OPTIONS } from '@/constants'
import store from '@/store'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'

import { isNumber } from '@vueuse/core'
import _ from 'lodash'
import { showRelativeDate } from './text'

const { formattedDate } = utils

const { COLLECTION_TYPE, ROLES } = constants

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return {
    ...toRefs(state),
    router: vm.$router,
  }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param arr
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

// Convert all array values into number, remove non-number
export const numberifyArray = arr => arr.map(x => parseInt(x, 10))

// check element in viewport
export const elementInViewport = (el, fullyInside) => {
  if (!el) return null
  fullyInside = fullyInside || false
  let top = el.offsetTop
  let left = el.offsetLeft
  const width = el.offsetWidth
  const height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }
  if (fullyInside) {
    return (
      top >= window.pageYOffset
      && left >= window.pageXOffset
      && top + height <= window.pageYOffset + window.innerHeight
      && left + width <= window.pageXOffset + window.innerWidth
    )
  }

  return (
    top >= window.pageYOffset
    && left >= window.pageXOffset
    && top <= window.pageYOffset + window.innerHeight
    && left <= window.pageXOffset + window.innerWidth
  )
}

export const deepMergeObject = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      Object.assign(source[key], deepMergeObject(target[key], source[key]))
    }
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

export const sumArray = array => array
  .filter(item => !isNaN(parseInt(item)))
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

export const getDefaultProductImage = (w, h) => {
  w = w || 400
  h = h || 400
  // const rand = Math.ceil(Math.random() * 100)
  if (w > 1000) {
    return `https://via.placeholder.com/${w}x${h}/#ddd`
  }
  return require('@/assets/images/elements/default-product.jpeg')
  // return `https://source.unsplash.com/collection/4585001/${w}x${h}?sig=${rand}`
}

export const convertArrayOfObjectToCSV = ({
  data,
  columnDelimiter,
  lineDelimiter,
}) => {
  if (!Array.isArray(data) || !data.length) return data
  columnDelimiter = columnDelimiter || ','
  lineDelimiter = lineDelimiter || '\n'

  const keys = Object.keys(data[0])

  let result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    let ctr = 0
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter
      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })
  return result
}

export function download(data, fileName = '') {
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', fileName)
  link.click()
}

export const downloadCSV = ({ csv, fileName }) => {
  if (Array.isArray(csv)) {
    csv = csv.join('\n')
  }
  fileName = fileName || 'export.csv'
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
  const data = encodeURI(csv)
  download(data, fileName)
}

export const downloadXlsx = ({ xlsx, fileName }) => {
  if (Array.isArray(xlsx)) {
    xlsx = xlsx.join('\n')
  }
  fileName = fileName || 'export.xlsx'
  if (!xlsx.match(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/i)) {
    xlsx = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsx}`
  }
  const data = encodeURI(xlsx)
  download(data, fileName)
}

export const downloadPDF = ({ pdf, fileName }) => {
  fileName = fileName || 'export.pdf'
  if (!pdf.match(/^data:application\/pdf/i)) {
    pdf = `data:application/pdf;base64,${pdf}`
  }
  download(pdf, fileName)
}

export const downloadZip = ({ zip, fileName }) => {
  if (Array.isArray(zip)) {
    zip = zip.join('\n')
  }
  fileName = fileName || 'export.zip'
  if (!zip.match(/^data:application\/zip/i)) {
    zip = `data:application/zip;base64,${zip}`
  }
  download(zip, fileName)
}

export const toFixed = (number, decimals) => +Number(+number).toFixed(decimals)

export const formatNumber = (price, decimal) => (price
  ? Number(price).toLocaleString('en-us', { minimumFractionDigits: decimal })
  : '')

export const getSizeInKb = (size = 0) => _.round(size / 1024, 2)

export const assignPayloadProduct = (items = [], productId) => {
  const itemValue = []
  items.forEach(item => {
    itemValue.push({
      sku: item.skuId || item.sku,
      value: 0,
    })
  })
  const products = [{
      productId,
      items: itemValue,
    },
  ]
  return products
}

export const toTitleCase = camelCase => {
  const result = camelCase.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const formatObject = (object = {}, excluded = { __v: 1 }) => Object.entries(object).reduce((acc, [key, value]) => {
  if (!excluded[key] && typeof value !== 'object') {
    acc[toTitleCase(key)] = value
  }
  return acc
}, {})

export const showOnboardChecklist = () => {
  const date = store.state.auth.profileData && store.state.auth.profileData.onboardCompletedAt
  const showPopup = (typeof date === 'undefined' || showRelativeDate(date) <= 30)
  return showPopup
}

export const parseResource = resource => {
  if (store.getters.entityType === ROLES.GUEST) {
    resource = 'Guest'
  }
  return resource
}

export const defaultImageUrl = 'https://via.placeholder.com/300/#fff'

export const imageLoadError = event => {
  event.target.src = defaultImageUrl
}

export const parseErrorObject = error => {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.errors) {
        if (error.response.data.errors[0].message) {
          return error.response.data.errors[0].message
        }
      } else if (error?.response?.data?.data?.message) {
        return error.response.data.data.message
      } else if (error?.response?.data?.message) {
        return error.response.data.message
      }
    }
  } else if (error.message) {
    return error.message
  }
  return ''
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export const isEllipsisActive = e => (e.offsetWidth < e.scrollWidth)

export const getDefaultProductPlaceholderImage = () => require('@/assets/images/elements/product-placeholder-200x300.png')

export const getWishlistTitle = (isRetailer = false, isPlural = false) => {
  const text = isRetailer ? 'Wishlist' : 'Selection'
  return isPlural ? `${text}s` : text
}

export const searchProductUsingFields = (product = {}, fields = [], searchText = '', itemFields = []) => {
  searchText = searchText?.trim()?.toLowerCase() || ''
  for (const field of fields) {
    if (product[`${field}`] && product[`${field}`].toLowerCase().indexOf(searchText) !== -1) {
      return true
    }
  }
  if (itemFields.length) {
    for (const field of itemFields) {
      for (const item of product.items) {
        if (item[`${field}`] && item[`${field}`].toLowerCase().indexOf(searchText) !== -1) {
          return true
        }
      }
    }
  }
  return false
}

export const isMarkupIncluded = (avgMarkup = [], value = 0) => {
  if (!isNumber(value)) {
    value = 0
  }
  let hasBrand = false
  avgMarkup.forEach(avgMarkup => {
    hasBrand = (MARKUP_FILTER_OPTIONS[avgMarkup].UPPER_LIMIT > value && MARKUP_FILTER_OPTIONS[avgMarkup].LOWER_LIMIT <= value)
  })
  return hasBrand
}

export const getFileName = (filename, link = '', extenstion = '') => {
  const fileDate = link.split('_')
  return `${filename}${fileDate[1] ? `_${fileDate[1].replaceAll('-', '/')}` : '' || `${extenstion}`}`
}

export const textTruncate = (str = '', hideSuffix = false, maxLength = 25, showCount = 10) => {
  if (str.length >= maxLength) {
    return `${str.substr(0, showCount) }...${ hideSuffix ? '' : str.substr(str.length - showCount, str.length)}`
  }
  return str
}

export const removeHtmlTags = (html = '') => {
  const regX = /(<([^>]+)>)/gi
  return html.replace(regX, '')
}
export const isValidMail = (mail = '') => {
  const regX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  return regX.test(mail)
}

export function getElapsedDaysCount(inputTime = null) {
  if (typeof inputTime === 'string' || isNaN(parseInt(inputTime))) {
    inputTime = new Date(inputTime).getTime()
  }
  if (inputTime) {
    const msPerDay = 60 * 60 * 1000 * 24
    const elapsed = new Date().getTime() - inputTime
    return Math.round(elapsed / msPerDay)
  }
  return null
}

export const parseCollectionDate = date => {
  if (!date) {
    return 'Order deadline: Ongoing'
  }
  const d = new Date(date)
  return `Order deadline: ${formattedDate(d)}`
}
export const getCollectionTypeText = type => Object.values(COLLECTION_TYPE).find(_ => _.LABEL === type).TEXT
export const parseCollectionTypeClass = type => {
  if (COLLECTION_TYPE.IN_STOCK.LABEL === type) {
    return 'success-bg'
  }
  return ''
}

export const absoluteNumber = number => Math.abs(Number(number))

export const invitingUserRole = () => {
  if (store.getters.entityType === ROLES.RETAILER) {
    return ROLES.BRAND
  }
  return ROLES.RETAILER
}

export const getMaxQuantity = (quantityAvailable = null) => {
  if (isNumber(quantityAvailable)) {
    return quantityAvailable
  }
  return Infinity
}

export const isProductValueDisabled = (quantityAvailable = null) => quantityAvailable === 0

export const hasProductMaxQuantity = (quantityAvailable = null) => isNumber(quantityAvailable)

export const isProductItemValid = (value = 0, quantityAvailable = null) => hasProductMaxQuantity(quantityAvailable) && (value > quantityAvailable)

export const searchDirectRetailer = (directRetailers = [], searchText = '') => {
  const retailers = directRetailers.map(retailer => ({
    ...retailer,
    entityName: retailer.entityName || `${retailer.firstName} ${retailer.lastName}`,
  }))
  if (!searchText) {
    return retailers
  }
  searchText = searchText.toLowerCase()

  return retailers.filter(retailer => retailer?.entityName.toLowerCase().includes(searchText) || retailer?.email.toLowerCase().includes(searchText))
}

export const getDownloadTypeName = downloadType => toTitleCase(downloadType)
